import React from "react"

import { graphql, Link, useStaticQuery } from "gatsby"
import styled from "styled-components"

import HeaderNavItem from "./HeaderNavItem"
import { colors, Nav1White } from "../../../styles/helpers"

const getData = graphql`
  {
    headerMenuTop: wpMenu(name: { eq: "Header Menu" }) {
      name
      menuItems {
        nodes {
          label
          url
          parentDatabaseId
          parentId
          id
          databaseId
          target
        }
      }
    }
  }
`

const HeaderMenu = () => {
  const data = useStaticQuery(getData)
  const { headerMenuTop } = data
  const allNavItems = headerMenuTop?.menuItems?.nodes
  const navItems = allNavItems.filter(item => item.parentDatabaseId === 0)
  const subItems = allNavItems.filter(item => item.parentDatabaseId !== 0)
  const navItemsWithSubs = navItems.map(item => {
    const itemWithSubs = subItems.filter(
      subItem => subItem.parentDatabaseId === item.databaseId
    )
    item.subItems = itemWithSubs
    return item
  })
  const headerNavigation = navItems ? (
    navItemsWithSubs.length > 0 ? (
      <HeaderNavStyled role="navigation" aria-label="primary">
        <ul className="mainNavWrapper">
          {navItemsWithSubs.map(item => {
            return <HeaderNavItem key={item.id} item={item} top={true} />
          })}
          <HighlightedLink>
            <Link to="/contact">Visit Our Taproom</Link>
          </HighlightedLink>
        </ul>
      </HeaderNavStyled>
    ) : null
  ) : null
  return headerNavigation
}

const HeaderNavStyled = styled.nav`
  display: none;
  width: 100%;

  @media (min-width: 768px) {
    display: block;
  }

  .mainNavWrapper {
    display: flex;
    align-items: center;
    align-self: center;
    justify-content: flex-end;
    width: 100%;

    @media (min-width: 1025px) {
      margin-top: 0;
    }
  }
`

const HighlightedLink = styled.li`
  background-color: ${colors.colorSecondary};
  padding: 2rem 4rem;

  a {
    ${Nav1White};
    text-transform: uppercase;
  }
`

export default HeaderMenu
