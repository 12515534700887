// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-about-js": () => import("./../../../src/templates/about.js" /* webpackChunkName: "component---src-templates-about-js" */),
  "component---src-templates-beer-js": () => import("./../../../src/templates/beer.js" /* webpackChunkName: "component---src-templates-beer-js" */),
  "component---src-templates-beers-js": () => import("./../../../src/templates/beers.js" /* webpackChunkName: "component---src-templates-beers-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-default-page-js": () => import("./../../../src/templates/defaultPage.js" /* webpackChunkName: "component---src-templates-default-page-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-taproom-js": () => import("./../../../src/templates/taproom.js" /* webpackChunkName: "component---src-templates-taproom-js" */)
}

